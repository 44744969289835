.Container {
}

.Box {
  background-color: #fff;
  padding: 0.3em 1em;
  padding-bottom: 2em;
  margin-bottom: 1em;
}

.DashboardButton {
  margin: 1em;
  padding: 0.5em;
}

.Alert {
  font-weight: bold;
  color: red;
}

.Regular {
  font-weight: bold;
  color: green;
}

.Box tr {
  border-bottom: 1px solid #ddd;
}

.Box table, th, td {
  border-collapse: collapse;
}

.Box th, td {
  padding: 0.5em;
}

.Box tr:hover {
  background-color: #d3e9ea;
}
