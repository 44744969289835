.Toolbar {
  width: 100%;
  height: 56px;
  background-color: #353238;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  box-sizing: border-box;
  z-index: 90;
}
