.ButtonContainer {
  display: flex;
  margin-top: 0;
}

.ButtonContainer button {
  width: 40%;
  margin-right: 1.8em;
  min-height: 2.2em;
  background-color: lightgrey;
  font-size: inherit;
  border: none;
  border-radius: 0.3em;
  cursor: pointer;
}

.active, .ButtonContainer button:hover {
  background-color: cadetblue !important;
  color: white !important;
}
