.MainContainer {
  display: flex;
}

.Options {
  width: 35%;
  margin: 0 auto;
}

.Slots {
  width: 65%;
  margin-left: 3em;
}

.OptionsContainer {
  background-color: white;
  margin: 1em 0;
  padding: 0.5em 1.3em;
}

.LoadMoreButton {
  height: 4em;
  font-size: 1.2em;
  margin-top: 1.5em;
  background-color: #749d9e;
  width: 65%;
  border: none;
  border-radius: 2em;
  font-weight: bold;
  color: white;
}

@media (max-width: 900px) {
  .Options {
    width: 45%;
  }

  .Slots {
    width: 55%;
  }
}

@media (max-width: 700px) {
  .MainContainer {
    display: block;
  }
  .Options {
    width: 95%
  }

  .Slots {
    width: 100%;
    margin-left: 0;
  }

  .LoadMoreButton {
    width: 100%;
  }
}
