.WeekButtons {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
}

@media (max-width: 1200px) and (min-width: 500px) {
  .WeekButtons {
    width: 90%;
  }
}

@media (max-width: 499px) {
  .WeekButtons {
    width: 99%;
    justify-content: space-between;
  }
}


.WeekButtons button {
  font-size: 1em;
  padding: 1em;
  margin-top: 1em;
  border: none;
  border-radius: 5px;
  max-width: 50%;
  min-width: 30%;
}

.unavailable {
  background-color: #ccc;
  color: #a6a9ae;
}

.unavailable i {
  border-color: #a6a9ae;
}

.available {
  background-color: #749d9e;
  color: white;
  cursor: pointer;
}

.available i {
  border-color: white;
}

.available:hover {
  background-color: #2a5e5f;
}

.available:active {
  animation: embiggen 0.1s linear;
}

@keyframes embiggen {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

i {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
