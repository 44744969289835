.Landing {
  width: 85%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
}

.LandingText {
  margin: 0.5em 0 0 1em;
  padding: 0 10px;
}

.LocButton {
  width: 16em;
  height: 10em;
  margin: 1em;
  text-align: left;
  background-color: white;
  padding: 0;
  font-size: inherit;
  cursor: pointer;
  font: inherit;
}

.LocButton h2 {
  margin: 0 0 0 1em;
}

@media (max-width: 1200px) and (min-width: 500px) {
  .Landing {
    width: 90%;
  }
}

@media (max-width: 499px) {
  .Landing {
    width: 99%;
  }
}
