.Explanation {
  background-color: white;
  padding: 1em;
  margin-bottom: 2em;
}

.Options {
  background-color: white;
  padding: 1em;
}
