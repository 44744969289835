.SubmitButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.SubmitButtons button {
  font-size: 1em;
  padding: 20px;
  border: 2px solid black;
  border-radius: 5px;
  margin: 2vh;
  max-width: 30%;
  min-width: 25vh;
}

.unavailable {
  background-color: #ccc;
  color: #a6a9ae;
}

.available {
  background-color: white;
  cursor: pointer;
}

.available:hover {
  background-color: #ea6100;
  color: white;
}

.available:active {
  animation: embiggen 0.1s linear;
}

@keyframes embiggen {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
