.MainContainer {
  background-color: #d3e9ea;
  padding: 70px 100px;
  min-height: 100vh;
  box-sizing: border-box;
}

@media (max-width: 1200px) and (min-width: 500px) {
  .MainContainer {
    padding: 5em 3em;
  }
}

@media (max-width: 499px) {
  .MainContainer {
    padding: 70px 10px;
  }
}
