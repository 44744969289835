.SearchResults {
  margin: 30px 0;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.Protocol {
  width: 29%;
  min-width: 250px;
  height: 6rem;
  background-color: #fff;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  border-radius: 15px;
  margin: 10px 20px;
  color: teal;
}

.Protocol p {
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 0px;
}

.Buttons {
  display: flex;
  justify-content: space-around;
}

.Buttons span {
  font-size: 2rem;
}

.Buttons span:hover {
  cursor: pointer;
}
