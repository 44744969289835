.Calendar h1 {
  text-align: center;
}

.CalendarContent {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.Week {
  width: 65%;
}

.Options {
  width: 35%;
  padding-right: 2em;
  box-sizing: border-box;
}

@media (max-width: 849px) {
  .Week {
    width: 100%;
  }

  .Options {
    width: 100%;
    padding: 0;
    margin-bottom: 2em;
  }
}
