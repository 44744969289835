.Slot {
  border: none;
  padding: 0 1em;
  margin: 0.5em;
  text-align: left;
  width: 16em;
  font-size: 1.1em;
  color: white;
  cursor: pointer;
}

.Available {
  background-color: cadetblue;
}

.Unavailable {
  color: #a6a9ae;
  background-color: #ccc;
  cursor: default;
}

.Slot h3 {
  margin-bottom: 0;
}
