.Slot {
  width: 100%;
  padding: 1em;
  margin: 1em;
  background-color: white;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.Highlighted {
  background-color: #ea6100;
}

@media only screen and (min-width: 768px) {
  .Slot {width: auto;}
}

.Slot button {
  color: #ea6100;
  margin: auto;
  font-size: 1.2rem;
  border: none;
  background: inherit;
  cursor: pointer;
}

.Highlighted button {
  color: white;
}

.Slot:hover {
  background-color: #f98e2a;
}

.Highlighted:hover {
  background-color: #ea6100;
}

.Slot:hover button {
  color: white;
}

.Slot:active {
  animation: embiggen 0.1s linear;
}

@keyframes embiggen {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
