.Container {
  background-color: white;
  margin: 2em 0;
  padding: 0.5em 1.3em;
}

.Container ul {
  padding-inline-start: 20px;
}

.Container li {
  padding-bottom: 0.5em;
  font-weight: bold;
}

.Container a {
  color: #749d9e;
}
