.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1em;
}

.Container select {
  height: 2em;
  background-color: #fff;
  color: #000;
  font-size: inherit;
  text-align: center;
}
