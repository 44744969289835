.ConsentForm {
  width: 80%;
  margin: 2em auto;
  background-color: white;
  padding: 1em 2em;
  box-sizing: border-box;
}

@media (max-width: 1200px) and (min-width: 800px) {
  .ConsentForm {
    width: 90%;
  }
}

@media (max-width: 799px) {
  .ConsentForm {
    width: 99%;
  }
}
